import React, { useState, useMemo } from 'react';

export const CartContext = React.createContext();

export function CartProvider(props) {
	// Safely parse JSON from local storage
	const safeParse = (item) => {
		try {
			return JSON.parse(item);
		} catch (error) {
			console.error('Error parsing JSON from local storage', error);
			return [];
		}
	};

	const initialItems = useMemo(() => safeParse(localStorage.getItem('cart')), []);
	const initialCodes = useMemo(() => safeParse(localStorage.getItem('codes')), []);

	const [items, setItems] = useState(initialItems || []);
	const [codes, setCodes] = useState(initialCodes || []);

	// Functions to update state and local storage
	const saveCart = (cart) => {
		localStorage.setItem('cart', JSON.stringify(cart));
		setItems(cart);
	};

	const saveCodes = (codes) => {
		localStorage.setItem('codes', JSON.stringify(codes));
		setCodes(codes);
	};

	const addItem = (id, gift = false) => {
		saveCart([...items, { id, quantity: 1, gift }]);
	};

	const removeItem = (id, gift) => {
		const index = items.findIndex((item) => item.id === id && item.gift === gift);
		if (index !== -1) {
			const newItems = [...items];
			newItems.splice(index, 1);
			saveCart(newItems);
		}
	};

	const toggleGift = (id, gift) => {
		const index = items.findIndex((item) => item.id === id && item.gift === gift);
		if (index !== -1) {
			const newItems = [...items];
			newItems[index] = { ...newItems[index], gift: !gift };
			saveCart(newItems);
		}
	};

	const clearCart = () => {
		saveCart([]);
	};

	const addCode = (code) => {
		saveCodes([...codes, code]);
	};

	const updateItemQuantity = (id, quantity, gift) => {
		if (quantity < 1) return removeItem(id, gift);
		saveCart(items.map((item) => (item.id === id && item.gift === gift ? { ...item, quantity } : item)));
	};

	const removeCode = (code) => {
		saveCodes(codes.filter((item) => item !== code));
	};

	const clearCodes = () => {
		saveCodes([]);
	};
	if (!props) return null;
	return (
		<CartContext.Provider
			value={{
				items,
				codes,
				addItem,
				removeItem,
				clearCart,
				updateItemQuantity,
				addCode,
				removeCode,
				clearCodes,
				CartProvider,
				toggleGift
			}}
		>
			{props.children}
		</CartContext.Provider>
	);
}
